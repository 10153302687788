import Vue from 'vue'
import Vuetify, { VLayout } from 'vuetify/lib'

import config from "../../vuetify.config.js"

Vue.use(Vuetify, {
    components: {
        VLayout,
    },
})

export default new Vuetify(config)
