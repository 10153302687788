let config = {
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#079992',
                accent: '#079992',
            },
        },
    },
}

export default config